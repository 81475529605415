class MenuModal extends HTMLElement {
    constructor() {
        super();

        this.tab = this.querySelector('.tab');
        this.details = this.querySelector('.menu-detail');
        this.container = this.querySelector('.menu-container');
        this.parentHeader = document.querySelector('.shopify-section-group-header-group');
        this.containerHeight = this.container.firstElementChild.clientHeight;
        this.background = this.querySelector('.menu-bg');
        this.background.style.height = `${this.containerHeight}px`;

        this.tab.addEventListener('mouseenter', this.onTabClick.bind(this));

        this.tab.setAttribute('role', 'button');
    }

    isOpen() {
        return this.details.hasAttribute('open');
    }

    onTabClick(event) {
        event.preventDefault();
        const currentTab = document.querySelector('.menu-detail[open="true"]');
        if(currentTab){
            //if another menu is open
            this.mutliOpen(event);
            //if this tab is open
            event.target.closest('.menu-detail') == currentTab ? event.target.closest('.menu-detail').setAttribute('open', true) : currentTab.closest('menu-modal').multiClose();

        } else {
            //if no menu is open
            event.target.closest('.menu-detail').hasAttribute('open') ? this.close() : this.open(event);
        }
    }

    onBodyClick(event) {
        console.log(event.target)
        if(!event.target.closest('.menu-detail') ||  document.querySelector('.menu-detail[open="true"]')) this.close();
    }

    mutliOpen(event) {
        this.onBodyClickEvent = this.onBodyClick.bind(this);
        event.target.closest('.menu-detail').setAttribute('open', true);
        event.target.closest('.nav-link').querySelector('.nav-active').classList.add('active');
        document.body.addEventListener('mouseenter', this.onBodyClickEvent);
        this.addEventListener('mouseleave', this.onBodyClickEvent);

        //contianer maxheight 0% -> 350px (this is consistent in design)
        this.container.style.height = `${this.containerHeight}px`;

        //opacity 0-1
        this.container.firstElementChild.classList.remove('opacity-0');
        this.container.firstElementChild.classList.add('opacity-1');
        this.container.classList.remove('opacity-0');
        this.container.classList.add('opacity-1');
        this.background.classList.remove('opacity-0', 'pointer-events-none');
        this.background.classList.add('opacity-1', 'pointer-events-auto');

        //remove height transition property
       

        //add opacity
        this.container.classList.add('opacity-1');
        
        //WISHLIST TODO:
        //once that animation completes
            //content opacity 0 - 1
            //image maxheight 0 - 100
    }

    open(event) {
        this.onBodyClickEvent = this.onBodyClick.bind(this);
        event.target.closest('.menu-detail').setAttribute('open', true);
        event.target.closest('.nav-link').querySelector('.nav-active').classList.add('active');
        document.body.addEventListener('mouseenter', this.onBodyClickEvent);
        this.addEventListener('mouseleave', this.onBodyClickEvent);

        //opacity 0-1
        this.container.firstElementChild.classList.remove('opacity-0');
        this.container.firstElementChild.classList.add('opacity-1');
        this.container.classList.remove('opacity-0');
        this.container.classList.add('opacity-1');
        this.background.classList.remove('opacity-0', 'pointer-events-none');
        this.background.classList.add('opacity-1', 'pointer-events-auto');

        //contianer maxheight 0% -> 350px (this is consistent in design)
        this.container.style.height = `${this.containerHeight}px`;
        this.container.classList.remove('h-0');

        //add height transition
      
        //WISHLIST TODO:
        //once that animation completes
            //content opacity 0 - 1
            //image maxheight 0 - 100
    }

    close() {
        this.details.removeAttribute('open');
        this.closest('.nav-link').querySelector('.nav-active').classList.remove('active');
        document.body.removeEventListener('mouseenter', this.onBodyClickEvent);
        this.removeEventListener('mouseleave', this.onBodyClickEvent);

        //add height transition


        //remove opacity:
        this.container.firstElementChild.classList.remove('opacity-1');
        this.container.firstElementChild.classList.add('opacity-0');
        this.container.classList.add('opacity-0');
        this.container.classList.remove('opacity-1');
        this.background.classList.add('opacity-0', 'pointer-events-none');
        this.background.classList.remove('opacity-1', 'pointer-events-auto');
        //remove max-h
        this.container.style.height = "0px";
    }

    multiClose() {
        this.details.removeAttribute('open');
        this.closest('.nav-link').querySelector('.nav-active').classList.remove('active');
        document.body.removeEventListener('mouseenter', this.onBodyClickEvent);
        this.removeEventListener('mouseleave', this.onBodyClickEvent);

        //add height transition


        //remove opacity:
        this.container.firstElementChild.classList.remove('opacity-1');
        this.container.firstElementChild.classList.add('opacity-0');
        this.container.classList.add('opacity-0');
        this.container.classList.remove('opacity-1');
        this.background.classList.add('opacity-0', 'pointer-events-none');
        this.background.classList.remove('opacity-1', 'pointer-events-auto');
        

        //remove max-h
        this.container.style.height = '0px';
    }
}

customElements.define('menu-modal', MenuModal)